// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import HelloSign from 'hellosign-embedded';

// Create the HelloSign Embedded instance.
// Only do this once!

let STAGING_QANDID_CLIENT_ID = '095ae30a6bb42534b7aedb2306085d63'
let QANDID_CLIENT_ID = '1910aab04cf5bff41bf9a479023b45a3'
let TRADELINE_STORE_CLIENT_ID = '5f09b0d91ea6700caf2a428ff94ae693'

let DOMAIN_LOCALHOST = 'localhost'
let DOMAIN_QANDID = 'qandid.com'
let DOMAIN_TRADELINE_STORE = 'tradeline.store'
let DOMAIN_STAGING_QANDID = 'staging.qandid.com'

let user = undefined

let local = new RegExp(DOMAIN_LOCALHOST);
let staging = new RegExp(DOMAIN_STAGING_QANDID);
let live = new RegExp(DOMAIN_QANDID);
let tradeline = new RegExp(DOMAIN_TRADELINE_STORE);


if (local.test(window.location.href) || staging.test(window.location.href)) {
    user = STAGING_QANDID_CLIENT_ID
} else if (live.test(window.location.href)) {
    user = QANDID_CLIENT_ID
} else if (tradeline.test(window.location.href)) {
    user = TRADELINE_STORE_CLIENT_ID
}

window.client = new HelloSign({
    clientId: user
});








